import { selectStockOutState } from '../index';
import { createSelector } from '@ngrx/store';
import { adapter, StockOutState } from '../reducer/stock-out.reducer';
import { selectRouteParam } from '@core/store/selectors/router.selectors';

// entity get the selectors (ReceivingOrders)
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
// select the array of order ids
export const selectAllReceivingOrdersIds = selectIds;
// select the dictionary of order entities
export const selectAllReceivingOrdersEntities = selectEntities;
// select the array of orders
export const selectAllReceivingOrders = selectAll;
// select the total order count
export const selectAllReceivingOrdersTotal = selectTotal;

export const selecStockOutOrdersIds = createSelector(selectStockOutState, selectAllReceivingOrdersIds);
export const selecStockOutOrdersEntities = createSelector(selectStockOutState, selectAllReceivingOrdersEntities);
export const selecStockOutOrders = createSelector(selectStockOutState, selectAllReceivingOrders);
export const selecStockOutOrdersTotal = createSelector(selectStockOutState, selectAllReceivingOrdersTotal);

export const selectLoading = createSelector(selectStockOutState, (state: StockOutState) => state && state.loading);
export const selectError = createSelector(selectStockOutState, (state: StockOutState) => state && state.error);
export const selectSuccess = createSelector(selectStockOutState, (state: StockOutState) => state && state.success);
export const selectLastScan = createSelector(selectStockOutState, (state: StockOutState) => state && state.lastScan);

export const selectStockOutScanningId = createSelector(
  selectStockOutState,
  (state: StockOutState) => state && state.scanningId
);

// Selector to get order packages from the order in router param
export const selectOrderPackages = createSelector(
  selecStockOutOrdersEntities,
  selectRouteParam('mp'),
  (entities, mpId) => {
    if (entities && mpId) {
      return entities[mpId]?.packages;
    }
    return null;
  }
);
